import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getUsuarios(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/administrador/usuarios', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUsuario(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/administrador/usuarios', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUsuario(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/administrador/usuarios/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/administrador/listar/roles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
